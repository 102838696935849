import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import notfoundImage from "../images/404_LuisVaz.jpg"

const NotFoundPage = () => {

	const [counter, setCounter] = useState(3);

	useEffect(() => {
		const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		if(counter == 0) {
			 navigate('/');
		}
		return () => clearInterval(timer);
	}, [counter]);


	return (
		<div className="page-404">
			<p className='kathy-room'>cabeça na lua</p>
			<img className="not-found" src={notfoundImage} alt="Luis Vaz logo" />
			<p className='about-text'>a voltar em {counter}</p>
		</div>
	)
}

export default NotFoundPage